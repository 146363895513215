import { lazy } from 'react';
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';

import {
  OrdersEdit,
  OrdersList,
  OrdersNew,
  OrdersPage,
  Registries,
  RegistriesList,
  RegistriesPreview,
  Reports,
  ReportsDocs,
  ReportsList,
} from '@src/pages';
import { RouterSuspense as S } from '@/lib/router/router-suspense';
import { ROUTES as R } from '@/constants/router.constants';
import { Redirect } from '@/lib/router/router-redirect';
import type { UserRole } from '@src/entities/user';

import { roleConfig } from '../../../entities/user/lib/role-config';

import AppLayout from './AppLayout/ui';
import { crumb } from './router-crumb';

const Balance = S(lazy(() => import('@app/routes/Balance')));
const OrdersView = S(lazy(() => import('@app/routes/Orders/components/View')));
const Workers = S(lazy(() => import('@src/pages/workers/page/ui/index')));
const Rates = S(lazy(() => import('@app/routes/Rates/index')));
const Playground = S(lazy(() => import('@app/routes/Playground')));

const isDev = import.meta.env.REACT_APP_ENV === 'development';

const getRoutesForRole = (role: UserRole) => {
  const routes = roleConfig[role]?.routes || [];

  return routes.map((route) => {
    switch (route) {
      //  -----------------------------ORDERS------------------------------
      case R.ORDERS.INDEX:
        return (
          <Route
            key={route}
            path={`${R.ORDERS.INDEX}`}
            handle={crumb(`/${R.ORDERS.INDEX}`)}
            element={OrdersPage}
          >
            <Route index element={<Redirect to={R.ORDERS.LIST} />} />
            <Route path={R.ORDERS.LIST} element={OrdersList}>
              <Route
                path={`${R.ORDERS.VIEW}/:id/:date/:job?`}
                element={OrdersView}
              />
            </Route>
            <Route
              path={`${R.ORDERS.LIST}/${R.ORDERS.EDIT}/:id/:date`}
              handle={crumb(`/${R.ORDERS.INDEX}/${R.ORDERS.EDIT}/`)}
              element={OrdersEdit}
            />
            <Route
              path={`${R.ORDERS.NEW}/*`}
              element={OrdersNew}
              handle={crumb(`/${R.ORDERS.INDEX}/${R.ORDERS.NEW}`)}
            />
            <Route
              path={`${R.ORDERS.RATES}/`}
              handle={crumb(`/${R.ORDERS.INDEX}/${R.ORDERS.RATES}`)}
              element={Rates}
            />
          </Route>
        );
      //-----------------------------BALANCE------------------------------
      case R.BALANCE.INDEX:
        return (
          <Route
            key={route}
            path={`${R.BALANCE.INDEX}/*`}
            handle={crumb(`/${R.BALANCE.INDEX}`)}
            element={Balance}
          />
        );
      //-----------------------------REGISTRIES------------------------------
      case R.REGISTRIES.INDEX:
        return (
          <Route
            key={route}
            path={`${R.REGISTRIES.INDEX}`}
            handle={crumb(`/${R.REGISTRIES.INDEX}`)}
            element={Registries}
          >
            <Route
              index
              element={
                <Redirect to={`/${R.REGISTRIES.INDEX}/${R.REGISTRIES.LIST}`} />
              }
            />
            <Route path={R.REGISTRIES.LIST} element={RegistriesList} />
            <Route
              path={R.REGISTRIES.PREVIEW}
              handle={crumb(`/${R.REGISTRIES.INDEX}/${R.REGISTRIES.PREVIEW}`)}
              element={RegistriesPreview}
            />
          </Route>
        );
      //-----------------------------WORKERS------------------------------
      case R.WORKERS.INDEX:
        return (
          <Route
            key={route}
            path={`${R.WORKERS.INDEX}/*`}
            handle={crumb(`/${R.WORKERS.INDEX}`)}
            element={Workers}
          />
        );

      // -----------------------------REPORTS----------------------------
      case R.REPORTS.INDEX:
        return (
          <Route
            key={route}
            path={`${R.REPORTS.INDEX}`}
            handle={crumb(`/${R.REPORTS.INDEX}`)}
            element={Reports}
          >
            <Route index element={<Redirect to={R.REPORTS.DOCS} />} />
            <Route path={R.REPORTS.DOCS} element={ReportsDocs}></Route>
            <Route path={R.REPORTS.LIST} element={ReportsList}></Route>
          </Route>
        );
      default:
        return null;
    }
  });
};

const firstAllowedRoute = (role: UserRole) => {
  const routes = roleConfig[role]?.routes || [];
  return <Redirect to={`${routes[0]}/`} />;
};

export default function router(role: UserRole) {
  return createBrowserRouter(
    createRoutesFromElements(
      <Route
        path={R.INDEX}
        element={<AppLayout />}
        errorElement={<AppLayout hasError />}
      >
        <Route index element={firstAllowedRoute(role)} />
        {getRoutesForRole(role)}
        {isDev && (
          <Route
            path={`${R.PLAYGROUND.INDEX}/*`}
            handle={crumb(`/${R.PLAYGROUND.INDEX}`)}
            element={Playground}
          />
        )}
        <Route path={R.NOTFOUND.INDEX} element={firstAllowedRoute(role)} />
      </Route>,
    ),
  );
}
